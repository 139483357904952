// /src/pages/index.js
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"


export default function Home({ data }) {
  return (
    <Layout>
      <Seo title="jamstack Blog Top" description="cloudflare gathby dev blog:cloudflareソリューション例,他サービス比較,SEO,エッジコンピューティング紹介,benchmark,CDN エッジにつてい,gatsby,contentful" keywords="cloudflare,cloudflare pages,gathby,contentful blog,CDN,Jamstack,SSG,core web vitals,CDN Edge,CDN エッジ,CDN エッジ,エッジコンピューティング,Edge Computing,モバイルフレンドリー,sitemap,seo,Search Engine Optimization,PWA"/>
      <Hero />
    </Layout>
)
}


