// src/components/hero.js
import React from "react"
import "../styles/hero.css"

import HERO_AUTHOR_IMG from "../../static/hero_author_image.png"
import CWV_SCORE_IMG from "../../static/asset/img/CoreWebVitalsScore.png"
import BT_SCORE_IMG from "../../static/asset/img/BuildTime.png"
import MOBILE_USABILITY_IMG from "../../static/google_search_consol_MobileUsability.png"
import SITEMAP_RET_IMG from "../../static/asset/img/site_seo.png"
import SITE_INDEXED_IMG from "../../static/asset/img/sitemap_result.png"
import PWA_READY_IMG from "../../static/asset/img/PWA_ready.png"
import PWA_RESULT_IMG from "../../static/asset/img/PWA_result.png"


export default function Hero() {
    return (
        <div className="hero">
            <p>
            <h2>
            spirited-away1000h Tech Blog
            </h2>
            <div className="hero-author">
                <img src={HERO_AUTHOR_IMG} className="hero-author-image" alt="avatar" width="40" height="40"></img>
                <p className="hero-author-text">
                このサイトは個人的に各種サービスを調査・使用した知見をメモ代わりに書いています。<br />
                掲載内容はあくまで私自身の見解であり、至らない箇所もある前提でお読みください。
                </p>
            </div>
            </p>
            <p>
            <h2>このサービスの構成</h2>
                <ul>
                    <li>ホスティングサービス 
                        <ul>
                            <li>CloudFlare page (jamstack) : 無料プラン</li>
                            <ul>
                                <li>http/3 : このページはhttp/3対応のブラウザ(chrome 等)で表示すると高速化されます。</li>
                            </ul>
                        </ul>
                    </li>
                    <li>構成
                        <ul>
                            <li>gatsby (SSG)</li>
                            <ul>
                                <li>PAW対応 : PWA対応ブラウザでインストール可能</li>
                                <ul>
                                    <li>PWAテスト検証はOSX : chrome</li>
                                </ul>
                            </ul>
                            <li>contentful (headless cms) : 無料プラン</li>
                            <ul>
                                <li>graphql</li>
                            </ul>
                        </ul>
                    </li>
                </ul>
            </p>
            <p>
            <h2>Core Web Vitals</h2>
            <br/>PWA 対応済表示になっています。
            <br/><center><img src={CWV_SCORE_IMG} alt="Core Web Vitals" width="50%" height="50%"></img></center>
            </p>
            <p>
            <h2>google search consol MobileUsability</h2>
            <br/>このページはモバイルフレンドリーです。<br/>
            <center><img src={MOBILE_USABILITY_IMG} alt="MobileUsability" width="30%" height="30%"></img></center>
            </p>
            <p>
            <h2>build time (Cloudflare pages)</h2>
            <br/><center><img src={BT_SCORE_IMG} alt="build time"  width="50%" height="50%"></img></center>
            </p>
            <h2>検索エンジン登録 : SEO</h2>
            <h3>sitemap</h3>
            <br/><center><img src={SITEMAP_RET_IMG} alt="sitemap結果"  width="50%" height="50%"></img></center>
            <h3>検索エンジン インデックス登録</h3>
            <br/><center><img src={SITE_INDEXED_IMG} alt="検索エンジン インデックス登録"  width="50%" height="50%"></img></center>
            <h3>PWA 対応</h3>
            <h4>Core Web Vitals PWA項目</h4>
            PWA INSTALLABLE
            <br/><center><img src={PWA_RESULT_IMG} alt="Core Web Vitals" width="50%" height="50%"></img></center>
            <h4>PWA対応確認</h4>
            chromeアドレスバー右側にPWAダウンロードボタンが表示される、選択するとインストールされます。<br/>
            <br/><center><img src={PWA_READY_IMG} alt="PWA対応"  width="50%" height="50%"></img></center>
            <h4>インストールしたPWAファイルの一覧表示</h4>
            当ページインストールすると、chromeで下記アドレスを入力すると表示される。<br/>
            <code>chrome://apps</code>
        </div>
    )
}
